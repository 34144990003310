import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { LanguageSelectComponent } from './usercontrols/language-select/language-select.component';
import { VattypeSelectComponent } from './usercontrols/vattype-select/vattype-select.component';
import { SafePipe } from "@app/pipes/safe.pipe";
import { ItemPricePipe } from "@shared/pipes/item-price.pipe";
import { ItemMaxPipe } from "@shared/pipes/item-max.pipe";
import { PluralPipe } from "@app/pipes/plural.pipe";
import { ZeroToEmptyPipe } from './pipes/zero-to-empty.pipe';
import { TranslationService } from '@app/services/translation.service';
import { TranslationHandler } from '@app/translations/translation.handler';
import { ConfirmModalComponent } from './confirm-modal/confirm.modal';
import { GridModule } from './grid';
import { GridToolsModule } from './grid-tools/grid-tools.module';
import { SearchAutocompleteModule } from './search-autocomplete/search-autocomplete.module';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { EnumTranslationPipe } from './pipes/enum-translation.pipe';
import { FormControlValidClassPipe } from './pipes/form-control-valid-class.pipe';
import { ValidationErrorsModule } from './validation/validation-errors.module';
import { PasswordInputComponent } from './password-input/password-input.component';
import { TelenetIconModule } from './telenet-icons';
import { CustomMessageComponent } from './messages/custom-message.component';
import { TelenetCheckboxComponent } from './checkbox/telenet-checkbox.component';
import { TelenetRadioButtonComponent } from './radio-button/telenet-radio-button.component';

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      TranslateModule.forRoot({
         defaultLanguage: 'E',
         missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: TranslationHandler,
            deps: [TranslationService],
         },
         useDefaultLang: false,
      }),
      GridModule,
      GridToolsModule,
      SearchAutocompleteModule,
      ValidationErrorsModule,
      TelenetIconModule
   ],
   declarations: [
      LanguageSelectComponent,
      VattypeSelectComponent,
      SafePipe,
      ItemPricePipe,
      ItemMaxPipe,
      PluralPipe,
      ZeroToEmptyPipe,
      ConfirmModalComponent,
      DateFormatPipe,
      EnumTranslationPipe,
      FormControlValidClassPipe,
      PasswordInputComponent,
      CustomMessageComponent,
      TelenetCheckboxComponent,
      TelenetRadioButtonComponent
   ],
   exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      TranslateModule,
      GridModule,
      GridToolsModule,
      SearchAutocompleteModule,
      ValidationErrorsModule,
      LanguageSelectComponent,
      VattypeSelectComponent,
      SafePipe,
      ItemPricePipe,
      ItemMaxPipe,
      PluralPipe,
      ZeroToEmptyPipe,
      ConfirmModalComponent,
      DateFormatPipe,
      FormControlValidClassPipe,
      EnumTranslationPipe,
      PasswordInputComponent,
      CustomMessageComponent,
      TelenetCheckboxComponent,
      TelenetRadioButtonComponent
   ],
   providers: []
})
export class SharedModule {
   static forRoot(): ModuleWithProviders<SharedModule> {
      return {
         ngModule: SharedModule,
         providers: []
      };
   }
}
