import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "app/app.settings";

@Injectable({
   providedIn: "root",
})
export class TranslationService {
   private readonly endpoint: string = `text-fragment`;

   public readonly translationError = "***ERROR***";

   constructor(private http: HttpClient, private appSettings: AppSettings) { }

   public getTranslation(key: string): Observable<string> {
      if (!key) {
         return of("");
      }

      const uri = `${this.appSettings.baseCoreApiUrl}/${this.endpoint}?code=${key}&language=${this.appSettings.selectedLanguage}`;
      const defaultRequestHeaders = new HttpHeaders()
         .set('Content-Type', 'text/plain; charset=utf-8')
         .append('X-SI-No-Response-Toast', 'true');

      const defaultRequestOptions: Object = {
         headers: defaultRequestHeaders,
         responseType: 'text'
      };

      return this.http.get<string>(uri, defaultRequestOptions).pipe(catchError(_ => of(this.translationError)));
   }
}
